<template>
  <div class="mb-6">
    <label class="af-custom-label mb-2">
      {{ question }}
    </label>
    <div class="answer-container">
      <div class="stars">
        <button
          v-for="i in range(1, 6)"
          :key="'star-' + i"
          :class="{
            hoveredStar: isHover(i),
            selectedStar: isSelect(i),
          }"
          @mouseenter="setHover(i)"
          @mouseleave="clearHover"
          @click="select(i)"
        >
          <b-icon 
            icon-pack="fas"
            icon="star"
            size="is-medium"
          ></b-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { range } from "lodash";

export default {
  name: "ApplicationFlowCheckboxQuestion",
  props: {
    question: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  emits: ['input'],
  data() {
    return {
      selected: null,
      hover: null,
    };
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    range,
    setHover(id) {
      this.hover = id;
    },
    clearHover() {
      this.hover = null;
    },
    select(id) {
      this.selected = id;
      this.hover = null;
      this.$emit("input", id);
    },
    isHover(i) {
      if (this.selected > this.hover) {
        return this.selected >= i;
      } else {
        return this.hover >= i;
      }
    },
    isSelect(i) {
      if (this.hover) {
        if (this.selected < this.hover) {
          return this.selected >= i;
        } else {
          return this.hover >= i;
        }
      } else {
        return this.selected >= i;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.answer-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .stars {
    display: flex;
    flex-direction: row;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      &.in-flow svg {
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.05));
      }

      cursor: pointer;
      background: none;
      border: none;
      font-size: 35px;
      color: lightgrey;

      &.hoveredStar {
        color: lighten($color: #ffc403, $amount: 35);
      }

      &.selectedStar {
        color: #ffc403;
      }
    }
  }
}
</style>